import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import moment, { MomentFormatSpecification } from "moment";

export const pluck = (array, key) => {
  return array.map((item) => item[key]);
};

export const hasHtml = (str: string | string[]) =>
  /<[a-z][\s\S]*>/i.test(Array.isArray(str) ? str[0] : str);

interface SiteMap {
  loc: string;
  lastmod: string;
  changefreq: string;
  priority: number;
}

export function createSitemap(links: SiteMap[]): string {
  return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
        ${links
          .map((item) => {
            return `
                    <url>
                        <loc>${item.loc}</loc>
                        <lastmod>${moment().toISOString()}</lastmod>
                        <changefreq>${item.changefreq}</changefreq>
                        <priority>${item.priority}</priority>
                    </url>
                `;
          })
          .join("")}
    </urlset>
    `;
}

interface GloableSiteMap {
  link: string;
  lastModify: MomentFormatSpecification;
}

export function createGlobalSitemap(links: GloableSiteMap[]): string {
  return `<?xml version="1.0" encoding="UTF-8"?>
    <sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${links
          .map((item) => {
            return `
                    <sitemap>
                        <loc>${item.link}</loc>
                        <lastmod>${item.lastModify}</lastmod>
                    </sitemap>
                `;
          })
          .join("")}
    </sitemapindex>
    `;
}

export function errorsToObject(errors) {
  let newErrorObj = {};
  if (errors) {
    forEach(errors, (value, key) => {
      Object.assign(newErrorObj, { [key]: value[0] });
    });
    return newErrorObj;
  }
  return errors;
}

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

//format general htmlsitemap data
export function formatHtmlSiteMap(data = []) {
  return data.map((item) => {
    const keys = Object.keys(item);
    return {
      name: isArray(item[keys[0]])
        ? transformToName(keys[0])
        : item[keys[0]].name,
      url: isArray(item[keys[0]]) ? "" : item[keys[0]].loc,
      links: isArray(item[keys[0]])
        ? item[keys[0]].map((innerItem) => ({
            name: innerItem.name,
            url: innerItem.loc,
          }))
        : [],
    };
  });
}

function transformToName(str) {
  switch (str) {
    case "country_home":
      return "Country Home";

    case "country_sitemaps":
      return "Country Pages";

    case "generic_category":
      return "Category Pages";

    case "country_category":
      return "Country Categories";

    case "generic_courses":
      return "Course Pages";

    case "country_courses":
      return "Country Courses";

    case "country_course":
      return "Country Course";

    case "country_course_cities":
      return "City Courses";

    case "others":
      return "Others";

    default:
      return "Others";
  }
}

export default function getFaqSchema(data: Faqs[]) {
  const questionAnswers = data.map((item) => ({
    "@type": "Question",
    name: item.question,
    acceptedAnswer: {
      "@type": "Answer",
      text: item.answer,
    },
  }));

  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: questionAnswers,
  };
}

export const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

// declare all characters
const CHARACTERS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export function generateString(length) {
  let result = " ";
  const charactersLength = CHARACTERS.length;
  for (let i = 0; i < length; i++) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function hasHTMLTags(str: string) {
  const regex = /<[^>]+>/; // Matches any HTML tag
  return regex.test(str);
}

export function moveElement(arr, fromIndex, toIndex) {
  if (!Array.isArray(arr)) {
    return [];
  }
  if (
    fromIndex >= arr.length ||
    toIndex >= arr.length ||
    fromIndex < 0 ||
    toIndex < 0
  ) {
    return arr;
  }

  // Remove the element from the original position
  const [element] = arr.splice(fromIndex, 1);

  // Insert the element at the new position
  arr.splice(toIndex, 0, element);

  return arr;
}
